import { User } from '@store/report/types';

export const updateTaksState = (userIndex: number, taskIndex: number) => (
  prevState: User[],
  field: string,
  value: any,
  addtional: {} = {},
) => {
  const newTasksState = prevState[userIndex]
    .tasks
    .map((t, i) => (i === taskIndex
      ? { ...t, [field]: value, ...addtional } : t));

  const newState = prevState.map((u, i) => (i === userIndex
    ? { ...u, tasks: newTasksState } : u));
  return [...newState];
};

export const updateUserState = (userIndex: number) => (
  prevState: User[],
  field: string,
  value: any,
) => {
  const newState = prevState.map((u, i) => (i === userIndex
    ? { ...u, [field]: value } : u));
  return [...newState];
};
