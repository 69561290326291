import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { PAGES } from '@router/pages';
import { useHistory } from 'react-router-dom';

import { TextField, Button } from '@material-ui/core';
import { useForm } from 'react-hook-form';

import { loginWorker } from '@store/auth/auth.actions';

import './login.scss';

export const LoginPage: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register, handleSubmit, formState: { errors },
  } = useForm<{
    email: string;
    password: string;
  }>({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = (data) => dispatch(
    loginWorker(data,
      { cOnSuccess: () => history.push(PAGES.REPORT_PAGE) }),
  );

  return (
    <div className="login">
      <div className="form">
        <form className="form__container" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            {...register('email', { required: true })}
            label="Email"
          />
          <div className="form__error">
            {errors.email && <span>This field is required</span>}
          </div>
          <TextField
            label="Password"
            type="password"
            {...register('password', { required: true })}
          />
          <div className="form__error">
            {errors.password && <span>This field is required</span>}
          </div>
          <Button variant="outlined" type="submit">Login</Button>
        </form>
      </div>
    </div>
  );
};
