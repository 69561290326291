import actionCreatorFactory from 'typescript-fsa';
import { createDefaultFetchWorker } from '@utils/builder/default-actions';
import { ProjectsResponse, User } from './types';
import { ErrorResponse } from '../types';

const actionCreator = actionCreatorFactory('REPORT');

export const getProjects = actionCreator.async<object, ProjectsResponse[], ErrorResponse>('GET_PROJECTS');
export const getReport = actionCreator.async<object, User[], ErrorResponse>('GET_REPORT');
export const sendEmail = actionCreator.async<object, any, ErrorResponse>('SEND_EMAIL');

export const getProjectsWorker = createDefaultFetchWorker(getProjects, '/projects/filters', 'get');
export const getReportWorker = (start, end, projectUid) => createDefaultFetchWorker(
  getReport, `/am/client-report?start=${start}&end=${end}&projectUid=${projectUid}`, 'get',
);
export const sendEmailWorker = createDefaultFetchWorker(sendEmail, '/am/client-report', 'post');
